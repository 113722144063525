.landing-section{
  background-image: url('./img/bg1.png');
  height: 592px;
  background-repeat: no-repeat;
  background-size: cover;
}
.swap-section{
  background-image: url('./img/bg2.png');
  height: 592px;
  background-repeat: no-repeat;
  background-size: cover;
}
.box{
  background: #FAFAFA;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  width: 325px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-item{
  width: 80px;
}

@media (max-width: 1280px) {  
  .box{
    background: #FAFAFA;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    width: 128px;
    padding: 10px 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.logo{
  font-family: Monoton;
}
.gradient-text{
  background: linear-gradient(90deg, #0F4388 9.33%, #B41C89 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-button{
  background: linear-gradient(90deg, #0F4388 9.33%, #B41C89 100%);
}
.gradient-border{
  /* border-image: linear-gradient(90deg, #0F4388 9.33%, #B41C89 100%) 1;
  border-width: 1px;
   */
  background: white;
  background-clip: padding-box;
  border-radius: 9999px;
  position: relative;
}
.gradient-border::after {
  position: absolute;
  top: -4px; bottom: -4px;
  left: -4px; right: -4px;
  background: linear-gradient(90deg, #0F4388 9.33%, #B41C89 100%);
  content: '';
  z-index: -1;
  border-radius: 9999px;
}
.h-596 {
  height: 596px;
}

.list-disc{
  list-style: disc !important;
}

.vertical-arrow{
  height: 73px;
  display: flex;
  justify-content: center;
  margin-top: -25px;
  position: relative;
}
.vertical-arrow-no-height{
  display: flex;
  justify-content: center;
  margin-top: -25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
.vertical-line{
  width: 2px;
  background: linear-gradient(90deg, #0F4388 9.33%, #B41C89 100%);
  height: 100%;
}
.vertical-point {
  width: 0;
  height: 0;
  border-top: 15px solid #B41C89;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -10px;
}
.arrow{
  width: 100%;
  position:relative;
}
.line {
  margin-top: 14px;
  width: 100%;
  background: linear-gradient(90deg, #0F4388 9.33%, #B41C89 100%);
  height: 2px;
  float: left;
}

.point {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #B41C89;
  float: right;
  position:absolute;
  right: 0;
  top: 5px;
}

.transparent-container{
  background: linear-gradient(270deg, rgba(215, 20, 137, 0.2), rgba(0, 71, 136, 0.2));
  border-radius: 20px;
  padding: 15px 25px;

}

.transparent-content{
  opacity: 1!important;
}
